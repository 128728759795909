body {
  background: #167549;
  color: #fefbda;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  height: 100vh;
  padding: 20px;
}

.importer {
  flex: 1;
  width: 33%;
  margin-right: 20px;

  &__textarea {
    min-height: 300px;
  }
}

.importer__form {
  display: flex;
  flex-direction: column;
}

.importer__header {
  font-size: 16px;
  margin: 0 0 20px 0;
}

.importer__button {
  background: #fefbda;
  border: 0;
  color: #333;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
  padding: 20px;
}

.button {
  background: #fefbda;
  border: 0;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
  padding: 20px;
  text-decoration: none;
}

.importer__checkbox {
  margin: 10px 0 0 0;

  input {
    margin-right: 4px;
    width: 24px;
    height: auto;
  }
}

.output-list {
  width: 67%;
  background: #fff;
  color: #333;
  flex: 2;
  padding: 20px;
  overflow-x: hidden;
}

.version {
  font-size: 10px;
  opacity: 0.5;
}

.only-winners {
  background: #167549;
  color: #fff;
  display: inline-block;
  margin-top: 0;
  padding: 8px 16px;
}

.item {
  &__title {
    margin-top: 0;
  }
}